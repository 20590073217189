import React, { useState } from "react";
import axios from "axios";

const ViewUserResponses = ({
  responses,
  setResponses,
  businessName,
  currentUsername,
}) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [comments, setComments] = useState({});
  const [activeComment, setActiveComment] = useState(null);

  const otherUserResponses = responses.filter(
    (response) => response.username !== currentUsername,
  );

  const handleDateClick = (date) => {
    setSelectedDate(date === selectedDate ? null : date);
  };

  const handleResponseClick = async (userId, date) => {
    try {
      const token = localStorage.getItem("access_token");
      await axios.put(
        `/api/v1/businesses/${businessName}/mark-response-read`,
        { user_id: userId, date_answered: date },
        { headers: { Authorization: `Bearer ${token}` } },
      );

      setResponses((prevResponses) =>
        prevResponses.map((response) =>
          response.user_id === userId && response.date_answered === date
            ? { ...response, read_responses: true }
            : response,
        ),
      );
    } catch (error) {
      console.error("Error marking response as read:", error);
    }
  };

  const handleCommentSubmit = async (userId, dateAnswered) => {
    try {
      const token = localStorage.getItem("access_token");
      const comment = comments[userId + "-" + dateAnswered];

      await axios.post(
        `/api/v1/businesses/${businessName}/submit-comment`,
        {
          user_id: userId,
          date_answered: dateAnswered,
          comment: comment,
        },
        { headers: { Authorization: `Bearer ${token}` } },
      );

      setResponses((prevResponses) =>
        prevResponses.map((response) =>
          response.user_id === userId && response.date_answered === dateAnswered
            ? { ...response, comment: comment }
            : response,
        ),
      );

      setActiveComment(null);
    } catch (error) {
      console.error("Error submitting comment:", error);
    }
  };

  const groupedResponses = otherUserResponses.reduce((acc, response) => {
    const date = response.date_answered;
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(response);
    return acc;
  }, {});

  return (
    <div className="font-serif">
      <h4 className="text-xl text-center mb-3 font-serif text-slate-200">
        User Responses
      </h4>
      {otherUserResponses.length === 0 ? (
        <p>No responses from other users available to view.</p>
      ) : (
        <div className="flex flex-col md:flex-row">
          <div className="w-full md:w-1/3 pr-2">
            <h5 className="font-serif text-lg mb-2">response dates</h5>
            {Object.keys(groupedResponses).map((date) => (
              <div
                key={date}
                className={`cursor-pointer p-2 mb-1 rounded text-md ${
                  selectedDate === date ? "bg-indigo-800" : "bg-slate-700"
                }`}
                onClick={() => handleDateClick(date)}
              >
                {new Date(date).toLocaleDateString()} (
                {groupedResponses[date].length} responses)
              </div>
            ))}
          </div>
          <div className="w-full md:w-2/3 pl-2 font-serif">
            {selectedDate && (
              <>
                <h5 className="text-lg font-semibold mb-2 text-indigo-300">
                  responses for {new Date(selectedDate).toLocaleDateString()}
                </h5>
                {groupedResponses[selectedDate].map((response, index) => (
                  <div
                    key={index}
                    className={`p-3 rounded-lg mb-3 cursor-pointer transition-colors duration-300 ${
                      response.read_responses ? "bg-indigo-950" : "bg-slate-700"
                    }`}
                    onClick={() =>
                      handleResponseClick(
                        response.user_id,
                        response.date_answered,
                      )
                    }
                  >
                    <p className="text-lg font-semibold text-slate-300">
                      {response.username} (Read:{" "}
                      {response.read_responses ? "Yes" : "No"})
                    </p>
                    {[1, 2, 3, 4].map((num) => (
                      <div key={num} className="mt-1">
                        <p className="text-md text-slate-200">
                          Q {num}: {response[`question_${num}`]}
                        </p>
                        <p className="text-md">{response[`answer_${num}`]}</p>
                      </div>
                    ))}
                    <div className="mt-2">
                      <button
                        className="bg-slate-200 text-slate-900 hover:bg-teal-500 font-bold text-sm py-1 px-2 rounded"
                        onClick={(e) => {
                          e.stopPropagation();
                          setActiveComment(
                            response.user_id + "-" + response.date_answered,
                          );
                        }}
                      >
                        {response.comment ? "Edit Comment" : "Add Comment"}
                      </button>
                      {activeComment ===
                        response.user_id + "-" + response.date_answered && (
                        <div className="mt-2">
                          <input
                            type="text"
                            className="w-full p-1 border rounded text-black text-sm"
                            value={
                              comments[
                                response.user_id + "-" + response.date_answered
                              ] ||
                              response.comment ||
                              ""
                            }
                            onChange={(e) => {
                              e.stopPropagation();
                              setComments({
                                ...comments,
                                [response.user_id +
                                "-" +
                                response.date_answered]: e.target.value,
                              });
                            }}
                            placeholder="Enter your comment"
                          />
                          <button
                            className="mt-1 bg-teal-400 hover:bg-green-600 text-slate-900 font-bold text-sm py-1 px-2 rounded"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleCommentSubmit(
                                response.user_id,
                                response.date_answered,
                              );
                            }}
                          >
                            Submit Comment
                          </button>
                        </div>
                      )}
                      {(response.comment ||
                        comments[
                          response.user_id + "-" + response.date_answered
                        ]) && (
                        <p className="mt-1 text-indigo-300 text-sm">
                          Comment:{" "}
                          {comments[
                            response.user_id + "-" + response.date_answered
                          ] || response.comment}
                        </p>
                      )}
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewUserResponses;
