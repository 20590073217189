import React, { useState, useEffect } from "react";
import axios from "axios";

const Teams = ({ businessName }) => {
  const [users, setUsers] = useState([]);
  const [teams, setTeams] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [teamName, setTeamName] = useState("");
  const [leaderId, setLeaderId] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    fetchUsers();
    fetchTeams();
  }, [businessName]);

  const fetchUsers = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/users`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
      setError("Failed to fetch users. Please try again.");
    }
  };

  const fetchTeams = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/teams`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setTeams(response.data);
    } catch (error) {
      console.error("Error fetching teams:", error);
      setError("Failed to fetch teams. Please try again.");
    }
  };

  const handleUserSelect = (userId) => {
    setSelectedUsers((prev) =>
      prev.includes(userId)
        ? prev.filter((id) => id !== userId)
        : [...prev, userId],
    );
  };

  const handleCreateTeam = async () => {
    try {
      const token = localStorage.getItem("access_token");
      if (!token) {
        setError("No authentication token found. Please log in again.");
        return;
      }

      const response = await axios.post(
        `/api/v1/businesses/${businessName}/teams`,
        {
          team_name: teamName,
          member_ids: selectedUsers.map((id) => parseInt(id)),
          leader_id: parseInt(leaderId),
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      console.log("Team created successfully:", response.data);
      setSuccessMessage("Team created successfully!");
      setTeamName("");
      setSelectedUsers([]);
      setLeaderId("");
      fetchTeams();
    } catch (error) {
      console.error("Error creating team:", error);
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
        setError(
          `Failed to create team: ${error.response.data.detail || "Unknown error"}`,
        );
      } else if (error.request) {
        console.error("Request:", error.request);
        setError("Failed to create team: No response received from server");
      } else {
        console.error("Error message:", error.message);
        setError(`Failed to create team: ${error.message}`);
      }
    }
  };

  return (
    <div className="mt-16 bg-slate-900 rounded-lg font-serif shadow-2xl p-6">
      <h4 className="text-2xl text-indigo-300 font-semibold mb-4">
        Manage Teams
      </h4>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      {successMessage && <p className="text-teal-500 mb-4">{successMessage}</p>}
      <div className="grid grid-cols-2 gap-4">
        <div>
          <h5 className="text-md font-semibold mb-2">Users</h5>
          {users.map((user) => (
            <div key={user.id} className="flex items-center mb-2">
              <input
                type="checkbox"
                id={`user-${user.id}`}
                checked={selectedUsers.includes(user.id)}
                onChange={() => handleUserSelect(user.id)}
                className="mr-2"
              />
              <label htmlFor={`user-${user.id}`}>{user.username}</label>
            </div>
          ))}
        </div>
        <div className="mt-[-90px]">
          <h5 className="bg-slate-950 text-center p-2 text-teal-500 rounded shadow-2xl text-2xl font-semibold mb-2">
            create team
          </h5>
          <input
            type="text"
            value={teamName}
            onChange={(e) => setTeamName(e.target.value)}
            placeholder="Team Name"
            className="w-full p-2 mb-2 bg-slate-800 rounded text-center"
          />
          <select
            value={leaderId}
            onChange={(e) => setLeaderId(e.target.value)}
            className="w-full p-5 mt-2 mb-2 bg-slate-900 rounded"
          >
            <option value="">select leader</option>
            {users.map((user) => (
              <option key={user.id} value={user.id}>
                {user.username}
              </option>
            ))}
          </select>
          <button
            onClick={handleCreateTeam}
            className="w-full py-2 mt-3 px-4 bg-indigo-900 text-white rounded hover:bg-blue-700"
          >
            create team
          </button>
        </div>
      </div>
      <div className="mt-8">
        <h5 className="text-md font-semibold mb-2">existing teams</h5>
        {teams.map((team) => (
          <div key={team.id} className="bg-slate-900 p-2 rounded mb-2">
            <p>
              <strong>{team.team_name}</strong>
            </p>
            <p>
              Leader: {users.find((u) => u.id === team.leader_id)?.username}
            </p>
            <p>
              Members:{" "}
              {team.member_ids
                .map((id) => users.find((u) => u.id === id)?.username)
                .join(", ")}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Teams;
