import React, { useState, useEffect } from "react";
import axios from "axios";

const TeamReviewPage = ({ businessName, onReviewSelect }) => {
  const [pendingReviews, setPendingReviews] = useState([]);
  const [completedReviews, setCompletedReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedReview, setExpandedReview] = useState(null);

  useEffect(() => {
    fetchTeamReviews();
  }, [businessName]);

  const fetchTeamReviews = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("access_token");
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/team-reviews`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setPendingReviews(response.data.pending_reviews);
      setCompletedReviews(response.data.completed_reviews);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching team reviews:", err);
      setError("Failed to fetch team reviews. Please try again.");
      setLoading(false);
    }
  };

  const toggleExpandReview = (reviewId) => {
    setExpandedReview(expandedReview === reviewId ? null : reviewId);
  };

  if (loading) return <div>Loading team reviews...</div>;
  if (error) return <div className="text-red-500">{error}</div>;

  return (
    <div className="bg-slate-800 rounded-lg p-4">
      <div className="grid grid-cols-1 md:grid-cols-1 gap-3">
        <div>
          <h5 className="text-xl border-b border-slate-600 p-1 mb-2 text-slate-300">
            Pending Reviews
          </h5>
          <div className="space-y-2 max-h-[30vh] overflow-y-auto">
            {pendingReviews.map((review) => (
              <div
                key={review.id}
                className={`p-2 rounded cursor-pointer ${
                  review.selected
                    ? "bg-indigo-700"
                    : "bg-slate-700 hover:bg-slate-600"
                }`}
                onClick={() => onReviewSelect(review)}
              >
                <p className="text-sm truncate">{review.reviews_text}</p>
              </div>
            ))}
          </div>
          <h5 className="text-xl border-b border-slate-600 p-1 my-2 text-slate-300">
            Completed Reviews
          </h5>
          <div className="space-y-2 max-h-[30vh] overflow-y-auto">
            {completedReviews.map((review) => (
              <div
                key={review.id}
                className="p-2 rounded bg-slate-700 cursor-pointer hover:bg-slate-600"
              >
                <div
                  className="flex justify-between items-center"
                  onClick={() => toggleExpandReview(review.id)}
                >
                  <p className="text-sm truncate">{review.reviews_text}</p>
                  <span className="text-md text-indigo-400">
                    {expandedReview === review.id ? "▲" : "▼"}
                  </span>
                </div>
                {expandedReview === review.id && (
                  <div className="mt-2 space-y-2">
                    {[1, 2, 3, 4, 5, 6, 7, 8].map(
                      (num) =>
                        review[`question_${num}`] && (
                          <div key={num} className="text-md">
                            <p className="text-gray-400">
                              {review[`question_${num}`]}
                            </p>
                            <p className="text-white ml-2">
                              {review[`answer_${num}`] || "No answer provided"}
                            </p>
                          </div>
                        ),
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamReviewPage;
