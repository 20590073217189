import React from "react";
import { Link } from "react-router-dom";
import {
  Users,
  HelpCircle,
  Star,
  BarChart,
  Shield,
  Eye,
  User,
  UserCircle,
  Home,
  TrendingUp,
  MessageSquare,
} from "lucide-react";

const Sidebar = ({ activeSection, setActiveSection, isAdmin }) => {
  const sections = [
    { name: "Dashboard", icon: Home, path: "/dashboard" },
    { name: "Responses", icon: MessageSquare, path: "/responses" },
    { name: "Reviews", icon: BarChart, path: "/my-reviews" },
  ];

  if (isAdmin) {
    sections.push(
      { name: "Analytics", icon: BarChart, path: "/analytics" },
      { name: "eNPS", icon: TrendingUp, path: "/enps" },
      { name: "Users", icon: UserCircle, path: "/users" },
      { name: "Create Reviews", icon: Star, path: "/create-reviews" },
      { name: "Questions", icon: HelpCircle, path: "/questions" },
      { name: "User Permissions", icon: Shield, path: "/user-permissions" },
    );
  }

  return (
    <div className="bg-slate-950 text-slate-200 font-serif w-64 space-y-5 py-7 px-2 absolute inset-y-0 left-0 transform -translate-x-full md:relative md:translate-x-0 transition duration-200 ease-in-out">
      {sections.map((section) => (
        <Link
          key={section.name}
          to={section.path}
          className={`flex items-center space-x-2 py-2.5 px-4 rounded transition duration-200 ${
            activeSection === section.name
              ? "bg-slate-800"
              : "hover:bg-indigo-900"
          }`}
          onClick={() => setActiveSection(section.name)}
        >
          <section.icon className="h-5 w-5" />
          <span>{section.name}</span>
        </Link>
      ))}
    </div>
  );
};

export default Sidebar;
