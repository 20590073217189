import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import PushReview from "./PushReview";

const QReviews = ({ businessName }) => {
  const navigate = useNavigate();
  const [reviews, setReviews] = useState([]);
  const [error, setError] = useState(null);
  const [showAllReviews, setShowAllReviews] = useState(false);

  useEffect(() => {
    fetchReviews();
  }, [businessName]);

  const fetchReviews = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/reviews`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setReviews(response.data);
    } catch (err) {
      console.error("Error fetching reviews:", err);
      setError("Failed to fetch reviews. Please try again.");
    }
  };

  const handleReviewClick = (reviewId) => {
    navigate(`/businesses/${businessName}/reviews/${reviewId}`);
  };

  const handleCreateReview = () => {
    navigate(`/businesses/${businessName}/create-review`);
  };

  const displayedReviews = showAllReviews ? reviews : reviews.slice(-5);

  return (
    <div className="p-5 bg-slate-900 rounded-lg shadow-2xl mt-[-25px]">
      <h3 className="text-4xl text-right font-semibold mb-16 font-serif">
        create reviews
      </h3>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-3">
        {/* Center the Create New Review button */}
        <div className="lg:col-span-2 flex justify-center mb-4 mt-2 text-center">
          <button
            onClick={handleCreateReview}
            className="w-full text-xl font-serif shadow-2xl py-2 bg-indigo-900 text-white rounded hover:bg-cyan-800"
          >
            create new review
          </button>
        </div>

        <div className="lg:col-span-2 bg-slate-900 p-2 shadow-2xl">
          <PushReview businessName={businessName} />
        </div>

        {/* Existing Reviews Column */}
        <div className="bg-slate-800 rounded-lg shadow-2xl p-6 lg:col-span-2">
          <h4 className="text-2xl font-semibold mb-4 font-serif text-indigo-300">
            existing reviews
          </h4>
          <div className="space-y-4 max-h-[calc(100vh-200px)] overflow-y-auto">
            {displayedReviews.map((review) => (
              <ReviewCard
                key={review.id}
                review={review}
                onReviewClick={handleReviewClick}
              />
            ))}
          </div>
          {reviews.length > 5 && (
            <button
              onClick={() => setShowAllReviews(!showAllReviews)}
              className="mt-4 px-4 py-2 bg-indigo-950 text-white rounded hover:bg-indigo-700"
            >
              {showAllReviews ? "Show Less" : `Show All (${reviews.length})`}
            </button>
          )}
        </div>
      </div>

      {error && <div className="mt-4 text-red-500">{error}</div>}
    </div>
  );
};

const ReviewCard = ({ review, onReviewClick }) => {
  return (
    <div
      className="bg-slate-700 p-2 rounded-lg cursor-pointer hover:bg-gray-600 transition-colors"
      onClick={() => onReviewClick(review.id)}
    >
      <h4 className="font-semibold mb-2">Review {review.id}</h4>
      <p className="text-sm text-gray-300">
        {review.reviews_text.substring(0, 100)}...
      </p>
    </div>
  );
};

export default QReviews;
