import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { LogOut } from "lucide-react";
import Sidebar from "./Sidebar";
import CreateUser from "./CreateUser";
import QuestionList from "./QuestionList";
import QReviews from "./QReviews";
import UserList from "./UserList";
import CurrentQuestions from "./CurrentQuestions";
import ManageUserPermissions from "./ManageUserPermissions";
import ViewUserResponses from "./ViewUserResponses";
import MyResponses from "./MyResponses";
import PushedReviewsUsers from "./PushedReviewsUsers";
import AdminReviews from "./AdminReviews";
import Teams from "./Teams";
import TeamReviewPage from "./TeamReviewPage";
import ViewAnsweredReviews from "./ViewAnsweredReviews";
import ENPS from "./ENPS";
import Analytics from "./Analytics";
import Responses from "./Responses";
import MyReviews from "./MyReviews";

const Dashboard = ({ user, onLogout }) => {
  const location = useLocation();
  const [activeSection, setActiveSection] = useState("Dashboard");
  const [userCreated, setUserCreated] = useState(null);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [surveyCompleted, setSurveyCompleted] = useState(false);
  const [latestSurveyResponse, setLatestSurveyResponse] = useState(null);
  const [responses, setResponses] = useState([]);
  const [hasReadResponses, setHasReadResponses] = useState(false);
  const [averageScore, setAverageScore] = useState(null);

  useEffect(() => {
    if (location.state && location.state.activeSection) {
      setActiveSection(location.state.activeSection);
    }
    if (user?.is_admin) {
      fetchUsers();
    }
    fetchLatestSurveyResponse();
    fetchResponses();
    checkReadResponses();
    fetchAverageScore();
  }, [user, location]);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.get(
        `/api/v1/businesses/${user.business_name}/users`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setUsers(response.data);
    } catch (err) {
      console.error("Error fetching users:", err);
      setError("Failed to fetch users. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const fetchLatestSurveyResponse = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.get("/api/v1/surveys/latest-response", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setLatestSurveyResponse(response.data.rating);
    } catch (err) {
      console.error("Error fetching latest survey response:", err);
    }
  };

  const fetchResponses = async () => {
    setLoading(true);
    setError(null);
    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.get(
        `/api/v1/businesses/${user.business_name}/viewable-responses`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setResponses(response.data);
    } catch (err) {
      console.error("Error fetching responses:", err);
      setError("Failed to fetch responses. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const checkReadResponses = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.get(
        `/api/v1/businesses/${user.business_name}/user-responses-read-status`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setHasReadResponses(response.data.has_read_responses);
    } catch (err) {
      console.error("Error checking read responses:", err);
    }
  };

  const fetchAverageScore = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.get(
        `/api/v1/businesses/${user.business_name}/average-score`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setAverageScore(response.data.average_score);
    } catch (err) {
      console.error("Error fetching average score:", err);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    onLogout();
  };

  const handleUserCreated = (newUser) => {
    setUserCreated(newUser);
    fetchUsers();
  };

  const handleSurveyComplete = (rating) => {
    setLatestSurveyResponse(rating);
    setSurveyCompleted(true);
  };

  const renderDashboardContent = () => (
    <>
      <div className="bg-indigo-950 ml-2 rounded-lg shadow-2xl font-serif">
        <h2 className="text-2xl font-serif ml-2 p-2">
          welcome, {user.username}
        </h2>
        {hasReadResponses && (
          <div className="text-white p-3 rounded-lg mb-4">
            Your responses have been read!
          </div>
        )}
        <div className="grid grid-cols-2 gap-4 mb-6 text-sm">
          {latestSurveyResponse !== null && (
            <p>
              <span className="text-gray-400">Your Latest Rating:</span>{" "}
              {latestSurveyResponse}/5
            </p>
          )}
          {user.is_admin && averageScore !== null && (
            <div className="col-span-2 bg-slate-900 p-3 shadow-2xl rounded-md text-right mr-5">
              <p className="text-3xl font-semibold text-slate-200">
                v.c average: {averageScore}
              </p>
            </div>
          )}
        </div>
        <CurrentQuestions businessName={user.business_name} />
      </div>
      {/*
       {!surveyCompleted && <Survey onComplete={handleSurveyComplete} />}
*/}{" "}
    </>
  );

  const renderActiveSection = () => {
    if (loading) return <div>Loading...</div>;
    if (error) return <div className="text-red-500">{error}</div>;

    switch (activeSection) {
      case "Dashboard":
        return renderDashboardContent();
      case "Reviews":
        return <MyReviews businessName={user.business_name} user={user} />;
      case "Responses":
        return (
          <Responses
            responses={responses}
            setResponses={setResponses}
            businessName={user.business_name}
            currentUsername={user.username}
          />
        );
      case "Analytics":
        return user.is_admin ? (
          <Analytics businessName={user.business_name} />
        ) : null;
      case "My Responses":
        return <MyResponses responses={responses} username={user.username} />;
      case "View Responses":
        return (
          <ViewUserResponses
            responses={responses}
            setResponses={setResponses}
            businessName={user.business_name}
            currentUsername={user.username}
          />
        );
      case "Users":
        return user.is_admin ? (
          <>
            <CreateUser
              businessName={user.business_name}
              onUserCreated={handleUserCreated}
            />
            {userCreated && (
              <div className="mt-4 p-4 bg-green-900 border border-green-700 text-green-100 rounded-lg">
                User created successfully: Auth ID: {userCreated.auth_user_id},
                Main ID: {userCreated.main_user_id}
              </div>
            )}
            <UserList
              businessName={user.business_name}
              currentUser={user}
              users={users}
              setUsers={setUsers}
            />
          </>
        ) : null;
      case "eNPS":
        return <ENPS businessName={user.business_name} />;
      case "Questions":
        return user.is_admin ? (
          <QuestionList businessName={user.business_name} />
        ) : null;
      case "Create Reviews":
        return user.is_admin ? (
          <QReviews businessName={user.business_name} />
        ) : (
          <PushedReviewsUsers businessName={user.business_name} />
        );
      case "Answer Reviews":
        return !user.is_admin ? (
          <PushedReviewsUsers businessName={user.business_name} />
        ) : null;
      case "Team Reviews":
        return <TeamReviewPage businessName={user.business_name} />;
      case "User Permissions":
        return user.is_admin ? (
          <>
            <ManageUserPermissions businessName={user.business_name} />
            <Teams businessName={user.business_name} />
          </>
        ) : null;
      case "My Reviews":
        return user.is_admin ? (
          <AdminReviews businessName={user.business_name} />
        ) : (
          <ViewAnsweredReviews businessName={user.business_name} />
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex h-screen bg-slate-950 text-gray-100">
      <Sidebar
        activeSection={activeSection}
        setActiveSection={setActiveSection}
        isAdmin={user.is_admin}
      />
      <div className="flex-1 flex flex-col overflow-hidden mt-3 mb-3">
        <header className="bg-slate-950 border-gray-700">
          <div className="flex items-center justify-between px-6 py-3">
            <div className="flex items-center space-x-2">
              <h1 className="text-4xl font-bold mb-1 text-teal-600 font-serif">
                matcha
              </h1>
              <h1 className="text-4xl align-left mr-14 text-indigo-600 font-serif">
                + {user.business_name}
              </h1>
            </div>
            <button
              onClick={handleLogout}
              className="flex items-center px-4 py-1 border border-transparent text-sm font-medium rounded-md text-white bg-gray-700 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
            >
              <LogOut className="mr-2 h-4 w-4" />
              Logout
            </button>
          </div>
        </header>
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-900">
          <div className="container mx-auto px-6 py-8">
            {renderActiveSection()}
          </div>
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
