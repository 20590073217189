import React, { useState, useEffect } from "react";
import axios from "axios";
import TeamReviewPage from "./TeamReviewPage";
import SharedWithMe from "./SharedWithMe";

const MyReviews = ({ businessName, user }) => {
  const [answeredReviews, setAnsweredReviews] = useState([]);
  const [reviewsToAnswer, setReviewsToAnswer] = useState([]);
  const [unansweredReviews, setUnansweredReviews] = useState([]);
  const [sharedReviews, setSharedReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [users, setUsers] = useState([]);
  const [showUserDropdown, setShowUserDropdown] = useState(null);
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedReview, setSelectedReview] = useState(null);
  const [answers, setAnswers] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [activeModalType, setActiveModalType] = useState("");

  useEffect(() => {
    fetchData();
    fetchUsers();
    fetchSharedReviews();
  }, [businessName]);

  const fetchData = async () => {
    try {
      const [answeredResponse, reviewsResponse] = await Promise.all([
        axios.get(`/api/v1/businesses/${businessName}/my-answered-reviews`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }),
        axios.get(`/api/v1/businesses/${businessName}/pushed-reviews`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }),
      ]);

      const answered = answeredResponse.data.answered_reviews || [];
      const pushedReviews = reviewsResponse.data.active_reviews || [];

      setAnsweredReviews(answered);
      setReviewsToAnswer(pushedReviews);

      // Filter out answered reviews
      if (pushedReviews.length > 0) {
        const answeredReviewIds = new Set(answered.map((review) => review.id));
        const filteredReviews = pushedReviews.filter(
          (review) => !answeredReviewIds.has(review.id),
        );
        setUnansweredReviews(filteredReviews);
      } else {
        setUnansweredReviews([]);
      }

      setLoading(false);
    } catch (err) {
      console.error("Error fetching reviews:", err);
      setError("Failed to fetch reviews. Please try again.");
      setLoading(false);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/all-users`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        },
      );
      setUsers(response.data || []);
    } catch (err) {
      console.error("Error fetching users:", err);
    }
  };

  const fetchSharedReviews = async () => {
    try {
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/shared-reviews`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        },
      );
      setSharedReviews(response.data || []);
    } catch (err) {
      console.error("Error fetching shared reviews:", err);
      setError("Failed to fetch shared reviews. Please try again.");
    }
  };

  const handleRecordClick = async (reviewId) => {
    if (!selectedUser) {
      alert("Please select a user to share with.");
      return;
    }
    try {
      await axios.post(
        `/api/v1/businesses/${businessName}/record-review-click/${reviewId}`,
        { shared_with: parseInt(selectedUser) },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
        },
      );
      console.log("Click recorded successfully");
      setShowUserDropdown(null);
      setSelectedUser("");
    } catch (err) {
      console.error("Error recording review click:", err);
    }
  };

  const handleUserSelect = (userId) => {
    setSelectedUser(userId);
  };

  const handleReviewSelect = (review, modalType) => {
    setSelectedReview(review);
    setAnswers({});
    if (modalType === "sharedWithMe") {
      const prefilledAnswers = {};
      review.questions.forEach((question, index) => {
        prefilledAnswers[`question_${index + 1}`] = question;
        prefilledAnswers[`answer_${index + 1}`] = review.answers[index] || "";
      });
      setAnswers(prefilledAnswers);
    }
    setModalOpen(true);
    setActiveModalType(modalType);
  };

  const handleAnswerChange = (questionKey, value) => {
    setAnswers({ ...answers, [questionKey]: value });
  };

  const handleSubmitAnswers = async () => {
    try {
      await axios.post(
        `/api/v1/businesses/${businessName}/submit-review-answer`,
        {
          review_id: selectedReview.id,
          answers: answers,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        },
      );
      alert("Answers submitted successfully!");
      setSelectedReview(null);
      setAnswers({});
      setModalOpen(false);

      // Refresh data
      setLoading(true);
      fetchData();
    } catch (err) {
      console.error("Error submitting answers:", err);
      alert("Failed to submit answers. Please try again.");
    }
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedReview(null);
    setActiveModalType("");
  };

  if (loading) return <div>Loading reviews...</div>;
  if (error) return <div className="text-red-500">{error}</div>;

  return (
    <div className="bg-slate-900 rounded-lg shadow-2xl p-4">
      <h3 className="text-3xl font-semibold mb-5 text-indigo-400 font-serif text-center">
        Reviews
      </h3>
      <div className="flex flex-col gap-6">
        {/* My Answered Reviews Section */}
        <div className="bg-slate-800 p-4 rounded-lg shadow-md">
          <h4 className="text-2xl font-semibold mb-3 text-teal-400">
            My Answered Reviews
          </h4>
          {answeredReviews.length === 0 ? (
            <p className="text-gray-400">
              You haven't answered any reviews yet.
            </p>
          ) : (
            <div className="space-y-3 max-h-[30vh] overflow-y-auto">
              {answeredReviews.map((review) => (
                <div
                  key={review.id}
                  className="bg-gray-800 p-3 rounded-lg shadow-md transition-all duration-300 ease-in-out cursor-pointer hover:bg-gray-700"
                  onClick={() => handleReviewSelect(review, "answeredReviews")}
                >
                  <div className="flex justify-between items-start">
                    <div className="w-3/4">
                      <h5 className="text-sm font-semibold font-serif mb-1 text-slate-300">
                        {review.reviews_text}
                      </h5>
                      <p className="text-xs text-gray-400 mb-1">
                        Answered on:{" "}
                        {new Date(review.expiration_date).toLocaleDateString()}
                      </p>
                    </div>
                    <div className="flex space-x-2">
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowUserDropdown(review.id);
                        }}
                        className="px-2 py-1 text-xs bg-slate-600 text-white rounded hover:bg-slate-700"
                      >
                        Share Review
                      </button>
                    </div>
                  </div>
                  {showUserDropdown === review.id && (
                    <div className="mt-2" onClick={(e) => e.stopPropagation()}>
                      <select
                        onChange={(e) => handleUserSelect(e.target.value)}
                        value={selectedUser}
                        className="w-full p-1 bg-gray-700 text-white rounded mb-2 text-sm"
                      >
                        <option value="">Select a user to share with</option>
                        {users.map((user) => (
                          <option key={user.id} value={user.id}>
                            {user.username}
                          </option>
                        ))}
                      </select>
                      <button
                        onClick={() => handleRecordClick(review.id)}
                        className="w-full px-2 py-1 bg-blue-600 text-white rounded hover:bg-blue-700 text-sm"
                      >
                        Submit
                      </button>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Reviews to Answer Section */}
        <div className="bg-slate-800 p-4 rounded-lg shadow-md">
          <h4 className="text-2xl font-semibold mb-3 text-teal-400">
            Reviews to Answer
          </h4>
          {unansweredReviews.length === 0 ? (
            <p className="text-gray-400">No reviews to answer at the moment.</p>
          ) : (
            <div className="space-y-3 max-h-[30vh] overflow-y-auto">
              {unansweredReviews.map((review) => (
                <div
                  key={review.id}
                  className={`bg-gray-800 p-3 rounded-lg shadow-md cursor-pointer ${
                    selectedReview?.id === review.id
                      ? "border-2 border-indigo-500"
                      : ""
                  }`}
                  onClick={() => handleReviewSelect(review, "reviewsToAnswer")}
                >
                  <h5 className="text-sm font-semibold font-serif mb-1 text-slate-300">
                    {review.reviews_text}
                  </h5>
                  <p className="text-xs text-gray-400 mb-1">
                    Expires on:{" "}
                    {new Date(review.expiration_date).toLocaleDateString()}
                  </p>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Team Reviews Section */}
        <div className="bg-slate-800 p-4 rounded-lg shadow-md">
          <h4 className="text-2xl font-semibold mb-3 text-teal-400">
            Team Reviews
          </h4>
          <TeamReviewPage
            businessName={businessName}
            onReviewSelect={(review) =>
              handleReviewSelect(review, "teamReviews")
            }
          />
        </div>

        {/* Shared With Me Section */}
        <div className="bg-slate-800 p-4 rounded-lg shadow-md">
          <h4 className="text-2xl font-semibold font-serif mb-3 text-indigo-400">
            Shared With Me
          </h4>
          <SharedWithMe
            businessName={businessName}
            onReviewSelect={(review) =>
              handleReviewSelect(review, "sharedWithMe")
            }
          />
        </div>
      </div>

      {/* Modal for Viewing and Answering Reviews */}
      {modalOpen && (
        <div className="fixed z-50 inset-0 flex items-center justify-center bg-black bg-opacity-75">
          <div className="bg-gray-800 p-6 rounded-lg shadow-xl w-full max-w-lg relative">
            <button
              onClick={closeModal}
              className="absolute top-2 right-2 text-white bg-red-600 hover:bg-red-700 p-2 rounded-full"
            >
              &times;
            </button>
            {selectedReview && (
              <div>
                <h5 className="text-xl font-semibold mb-3 text-center text-indigo-300">
                  {activeModalType === "reviewsToAnswer"
                    ? "Answer Review"
                    : activeModalType === "teamReviews"
                      ? "Team Review"
                      : activeModalType === "sharedWithMe"
                        ? "Shared Review"
                        : "Answered Review"}
                </h5>
                <h6 className="text-lg font-semibold mb-2">
                  {selectedReview.reviews_text || selectedReview.review_text}
                </h6>
                {activeModalType === "sharedWithMe"
                  ? selectedReview.questions.map((question, index) => (
                      <div key={index} className="mb-2">
                        <label className="block text-sm font-medium">
                          {question}
                        </label>
                        <textarea
                          className="mt-1 block w-full rounded-md border-gray-600 bg-gray-700 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm"
                          rows="2"
                          value={answers[`answer_${index + 1}`] || ""}
                          readOnly
                        ></textarea>
                      </div>
                    ))
                  : activeModalType === "answeredReviews"
                    ? selectedReview.questions.map((question, index) => (
                        <div key={index} className="mb-2">
                          <label className="block text-sm font-medium">
                            {question}
                          </label>
                          <p className="mt-1 block w-full rounded-md border-gray-600 bg-gray-700 text-white shadow-sm p-2">
                            {selectedReview.answers[index] || "Not answered"}
                          </p>
                        </div>
                      ))
                    : [1, 2, 3, 4, 5, 6, 7, 8].map((num) => (
                        <div key={num} className="mb-2">
                          <label className="block text-sm font-medium">
                            {selectedReview[`question_${num}`]}
                          </label>
                          <textarea
                            className="mt-1 block w-full rounded-md border-gray-600 bg-gray-700 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm"
                            rows="2"
                            value={answers[`answer_${num}`] || ""}
                            onChange={(e) =>
                              handleAnswerChange(
                                `answer_${num}`,
                                e.target.value,
                              )
                            }
                            readOnly={activeModalType === "answeredReviews"}
                          ></textarea>
                        </div>
                      ))}
                {activeModalType !== "sharedWithMe" &&
                  activeModalType !== "answeredReviews" && (
                    <button
                      onClick={handleSubmitAnswers}
                      className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Submit Answers
                    </button>
                  )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MyReviews;
