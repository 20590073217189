import React, { useState } from "react";
import axios from "axios";

const CreateUser = ({ businessName, onUserCreated }) => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccessMessage("");
    setLoading(true);

    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.post(
        `/api/v1/businesses/${businessName}/users`,
        {
          username,
          email,
          password,
          is_admin: isAdmin,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        },
      );

      console.log("User created:", response.data);
      onUserCreated(response.data);
      setSuccessMessage(
        "User created successfully. A verification email has been sent.",
      );

      // Clear form
      setUsername("");
      setEmail("");
      setPassword("");
      setIsAdmin(false);
    } catch (err) {
      console.error("Error creating user:", err.response?.data);
      if (err.response?.status === 400 && err.response?.data?.detail) {
        const errorDetail = err.response.data.detail;
        if (
          typeof errorDetail === "object" &&
          errorDetail.code === "EMAIL_ALREADY_EXISTS"
        ) {
          setError(errorDetail.message);
          setEmail(""); // Clear the email field
        } else if (
          typeof errorDetail === "object" &&
          errorDetail.code === "USERNAME_ALREADY_EXISTS"
        ) {
          setError(errorDetail.message);
          setUsername(""); // Clear the username field
        } else {
          setError(
            typeof errorDetail === "string"
              ? errorDetail
              : "Failed to create user. Please try again.",
          );
        }
      } else {
        setError("An unexpected error occurred. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex justify-center items-center w-full mb-[-50px]">
      <div className="w-full max-w-6xl bg-slate-800 p-5 rounded-lg text-indigo-400">
        <h2 className="text-2xl font-semibol mb-4 font-serif">
          create new user
        </h2>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        {successMessage && (
          <p className="text-green-500 mb-4">{successMessage}</p>
        )}
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="">
            <label
              htmlFor="username"
              className="block text-sm font-medium text-gray-200"
            >
              Username
            </label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
              className="mt-1 p-1 block bg-slate-950 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-300 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-200"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="mt-1 block  bg-slate-950 p-1 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-200"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="mt-1 p-1 block w-full  bg-slate-950 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label htmlFor="isAdmin" className="inline-flex items-center">
              <input
                type="checkbox"
                id="isAdmin"
                checked={isAdmin}
                onChange={(e) => setIsAdmin(e.target.checked)}
                className="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
              <span className="ml-2 text-teal-500">Is Admin</span>
            </label>
          </div>
          <button
            type="submit"
            disabled={loading}
            className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-900 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
          >
            {loading ? "Creating..." : "Create User"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateUser;
