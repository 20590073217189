import React, { useState, useEffect } from "react";
import axios from "axios";

const SharedWithMe = ({ businessName, onReviewSelect }) => {
  const [sharedReviews, setSharedReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchSharedReviews();
  }, [businessName]);

  const fetchSharedReviews = async () => {
    try {
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/shared-reviews`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        },
      );
      setSharedReviews(response.data);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching shared reviews:", err);
      setError("Failed to fetch shared reviews. Please try again.");
      setLoading(false);
    }
  };

  if (loading) return <div>Loading shared reviews...</div>;
  if (error) return <div className="text-red-500">{error}</div>;

  return (
    <div className="space-y-4">
      {sharedReviews.length === 0 ? (
        <p>No reviews have been shared with you yet.</p>
      ) : (
        sharedReviews.map((review) => (
          <div
            key={review.id}
            className="bg-gray-800 p-2 mr-2 ml-2 rounded-lg shadow-2xl transition-all duration-300 ease-in-out"
          >
            <div className="flex justify-between items-start">
              <div className="w-3/4 ml-3">
                <div className="flex items-center mb-1">
                  <h4 className="text-lg font-semibold font-serif text-slate-300 mr-4">
                    {review.review_text}
                  </h4>
                  <p className="text-sm text-gray-400">
                    Shared by: {review.shared_by_username} on{" "}
                    {new Date(review.shared_at).toLocaleDateString()}
                  </p>
                </div>
              </div>
              <button
                onClick={() => onReviewSelect(review)}
                className="px-2 text-xs mt-1 mb-1 mr-2 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-700"
              >
                View Answers
              </button>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default SharedWithMe;
