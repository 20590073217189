import React, { useState, useEffect } from "react";
import axios from "axios";

const ManageUserPermissions = ({ businessName }) => {
  const [users, setUsers] = useState([]);
  const [selectedViewer, setSelectedViewer] = useState("");
  const [selectedViewees, setSelectedViewees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/users-with-ids`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setUsers(response.data);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching users:", err);
      setError("Failed to fetch users. Please try again.");
      setLoading(false);
    }
  };

  const handleViewerChange = (e) => {
    const username = e.target.value;
    setSelectedViewer(username);
    if (username) {
      const selectedUser = users.find((user) => user.username === username);
      setSelectedViewees(selectedUser ? selectedUser.view_questions_ids : []);
    } else {
      setSelectedViewees([]);
    }
  };

  const handleVieweeChange = (mainUserId) => {
    setSelectedViewees((prev) =>
      prev.includes(mainUserId)
        ? prev.filter((id) => id !== mainUserId)
        : [...prev, mainUserId],
    );
  };

  const handleSubmit = async () => {
    try {
      const token = localStorage.getItem("access_token");
      await axios.put(
        `/api/v1/businesses/${businessName}/users/${selectedViewer}/set-viewable-users`,
        { viewable_user_ids: selectedViewees },
        { headers: { Authorization: `Bearer ${token}` } },
      );
      const viewableUsernames = users
        .filter((user) => selectedViewees.includes(user.main_id))
        .map((user) => user.username)
        .join(", ");
      setSuccessMessage(
        `${selectedViewer} can now view information from ${viewableUsernames}`,
      );
      setSelectedViewer("");
      setSelectedViewees([]);
      fetchUsers(); // Refresh the user list to update the displayed information
    } catch (err) {
      console.error("Error updating permissions:", err);
      setError("Failed to update permissions. Please try again.");
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div className="text-red-500">{error}</div>;

  return (
    <div className="mt-8 bg-slate-900 rounded-lg shadow-lg p-6 font-serif">
      <h4 className="text-3xl text-right text-indigo-300 font-semibold mb-4">
        Manage User Permissions
      </h4>
      <div className="mb-4">
        <label className="block mb-2">Select Viewer:</label>
        <select
          value={selectedViewer}
          onChange={handleViewerChange}
          className="w-full p-2 bg-slate-800 rounded"
        >
          <option value="">Select a user</option>
          {users.map((user) => (
            <option key={user.id} value={user.username}>
              {user.username}
            </option>
          ))}
        </select>
      </div>
      {selectedViewer && (
        <div className="mb-4">
          <label className="block mb-2">Select Users to View:</label>
          {users.map((user) => (
            <div key={user.main_id} className="flex items-center mb-2">
              <input
                type="checkbox"
                id={`user-${user.main_id}`}
                checked={selectedViewees.includes(user.main_id)}
                onChange={() => handleVieweeChange(user.main_id)}
                className="mr-2"
              />
              <label htmlFor={`user-${user.main_id}`}>{user.username}</label>
            </div>
          ))}
        </div>
      )}
      {selectedViewer && (
        <button
          onClick={handleSubmit}
          className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
        >
          Update Permissions
        </button>
      )}
      {successMessage && (
        <div className="mt-4 p-2 bg-teal-700 text-white rounded">
          {successMessage}
        </div>
      )}
      <div className="mt-8">
        <div className="mt-8">
          <h5 className="text-2xl font-semibold mb-2">
            current user permissions:
          </h5>
          <div className="grid grid-cols-2 gap-4">
            {users.map((user) => (
              <div key={user.main_id} className="bg-slate-900 p-2 rounded">
                <span className="font-semibold text-indigo-400">
                  {user.username}
                </span>{" "}
                | can view IDs:{" "}
                {user.view_questions_ids.length > 0
                  ? user.view_questions_ids.join(", ")
                  : "None"}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageUserPermissions;
