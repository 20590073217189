import React, { useState } from "react";

const MyResponses = ({ responses, username }) => {
  const myResponses = responses.filter(
    (response) => response.username === username,
  );

  // State to track which response is currently selected for the modal
  const [selectedResponse, setSelectedResponse] = useState(null);

  const handleCloseModal = () => {
    setSelectedResponse(null); // Close the modal
  };

  return (
    <div className="text-slate-300 text-md rounded-lg font-serif">
      <h4 className="border-b border-slate-400 text-xl mb-3 text-center">
        My Responses
      </h4>

      {myResponses.length === 0 ? (
        <p>You haven't submitted any responses yet.</p>
      ) : (
        myResponses.map((response, index) => (
          <div
            key={index}
            className="p-3 rounded-lg bg-slate-900 mb-3 shadow-md border-b border-slate-500 cursor-pointer"
            onClick={() => setSelectedResponse(response)}
          >
            <p className="text-sm text-gray-100 mb-1">
              Date: {new Date(response.date_answered).toLocaleDateString()}
            </p>
          </div>
        ))
      )}

      {/* Modal Pop-out */}
      {selectedResponse && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
          <div className="bg-slate-900 p-6 rounded-lg shadow-xl max-w-lg w-full relative">
            <button
              onClick={handleCloseModal}
              className="absolute top-2 right-2 text-white bg-teal-300 hover:bg-red-700 p-2 rounded-full"
            >
              &times;
            </button>
            <h5 className="text-xl font-semibold mb-3 text-center text-indigo-300">
              Response Details
            </h5>
            <p className="text-lg text-gray-400 mb-1">
              Date:{" "}
              {new Date(selectedResponse.date_answered).toLocaleDateString()}
            </p>
            {[1, 2, 3, 4].map((num) => (
              <div key={num} className="mt-1">
                <p className="text-lg text-gray-400">
                  Q {num}: {selectedResponse[`question_${num}`]}
                </p>
                <p className="text-md">{selectedResponse[`answer_${num}`]}</p>
              </div>
            ))}
            {selectedResponse.comment && (
              <div className="mt-2 bg-slate-700 p-2 rounded">
                <p className="text-sm font-semibold text-yellow-300">
                  Comment:
                </p>
                <p className="text-white text-lg">{selectedResponse.comment}</p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MyResponses;
