import React, { useState, useEffect } from "react";
import axios from "axios";

const CurrentQuestions = ({ businessName }) => {
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [alreadyAnswered, setAlreadyAnswered] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [sliderValue, setSliderValue] = useState(3);
  const [sliderConfirmed, setSliderConfirmed] = useState(false);

  const fetchQuestions = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/current-questions`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        },
      );
      setQuestions(response.data.questions);
      setAlreadyAnswered(response.data.already_answered);
      setLoading(false);
    } catch (err) {
      setError("Failed to fetch questions. Please try again.");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchQuestions();
  }, [businessName]);

  const handleInputChange = (questionId, value) => {
    setAnswers((prev) => ({ ...prev, [questionId]: value }));
  };

  const handleSliderConfirm = () => {
    setAnswers((prev) => ({
      ...prev,
      [questions[0].id]: sliderValue.toString(),
    }));
    setSliderConfirmed(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!sliderConfirmed) {
      setSubmitError("Please confirm your response for the first question.");
      return;
    }
    setSubmitting(true);
    setSubmitError(null);

    try {
      const formattedAnswers = questions.map((question, index) => ({
        question_id: question.id,
        answer: answers[question.id] || "",
        column_suffix: index === 0 ? "" : `_${index + 1}`,
      }));

      await axios.post(
        `/api/v1/businesses/${businessName}/answer-questions`,
        { answers: formattedAnswers },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        },
      );

      setAlreadyAnswered(true);
    } catch (err) {
      setSubmitError("Failed to submit answers. Please try again.");
    } finally {
      setSubmitting(false);
    }
  };
  const getSliderBackground = () => {
    const percentage = ((sliderValue - 1) / 4) * 100;
    return `linear-gradient(to right, #0D9488 ${percentage}%, #374151 ${percentage}%)`;
  };

  if (loading) return <div>Loading questions...</div>;
  if (error) return <div className="text-red-500">{error}</div>;

  if (alreadyAnswered) {
    return (
      <div className="bg-slate-900 rounded-lg shadow-2xl p-6 mb-6">
        <h3 className="text-5xl font-semibold mb-4 text-indigo-500 font-serif">
          vibe check
        </h3>
        <p className="text-cyan-500 mb-4">
          You have already answered today's questions. Thank you!
        </p>
      </div>
    );
  }

  return (
    <div className="bg-slate-900 rounded-lg shadow-2xl p-6 mb-6">
      <h3 className="text-5xl font-semibold mb-4 text-indigo-500 font-serif">
        vibe check
      </h3>
      <form onSubmit={handleSubmit}>
        {questions.map((question, index) => (
          <div key={question.id} className="mb-6">
            <p className="text-lg mb-2 text-white">{question.question_text}</p>
            {index === 0 ? (
              <div className="flex flex-col sm:flex-row items-center">
                <div className="w-full sm:w-1/2">
                  <input
                    type="range"
                    min="1"
                    max="5"
                    step="1"
                    value={sliderValue}
                    onChange={(e) => setSliderValue(parseInt(e.target.value))}
                    className="w-full h-2 rounded appearance-none cursor-pointer mb-2"
                    style={{ background: getSliderBackground() }}
                    disabled={sliderConfirmed}
                    required
                  />
                  <div className="flex justify-between text-sm text-gray-400">
                    <span>1</span>
                    <span>2</span>
                    <span>3</span>
                    <span>4</span>
                    <span>5</span>
                  </div>
                </div>
                <div className="flex items-center mt-4 sm:mt-0 sm:ml-4">
                  <span className="text-4xl ml-5 font-medium text-teal-500 mr-10">
                    {sliderValue}
                  </span>
                  <button
                    type="button"
                    onClick={handleSliderConfirm}
                    disabled={sliderConfirmed}
                    className={`flex items-center px-3 py-2 bg-indigo-900 text-white rounded hover:bg-indigo-500 focus:outline-none ${
                      sliderConfirmed ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 mr-2"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 00-1.414 0L8 12.586 4.707 9.293a1 1 0 00-1.414 1.414l4 4a1 1 0 001.414 0l8-8a1 1 0 000-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                    {sliderConfirmed ? "Confirmed" : "Confirm"}
                  </button>
                </div>
              </div>
            ) : (
              <input
                type="text"
                value={answers[question.id] || ""}
                onChange={(e) => handleInputChange(question.id, e.target.value)}
                className="w-full p-2 bg-slate-800 text-white rounded"
                required
              />
            )}
          </div>
        ))}
        <button
          type="submit"
          disabled={submitting}
          className="w-full py-2 px-4 bg-indigo-600 text-white rounded hover:bg-indigo-700 disabled:opacity-50"
        >
          {submitting ? "Submitting..." : "Submit Answers"}
        </button>
        {submitError && <p className="text-red-500 mt-2">{submitError}</p>}
      </form>
    </div>
  );
};

export default CurrentQuestions;
