import React from "react";
import MyResponses from "./MyResponses";
import ViewUserResponses from "./ViewUserResponses";

const Responses = ({
  responses,
  setResponses,
  businessName,
  currentUsername,
}) => {
  return (
    <div className="bg-slate-900 rounded-lg shadow-2xl p-2 w-full mx-auto">
      {/* Increased padding and set width to full */}

      <h3 className="text-3xl mb-6 text-slate-200 font-serif text-center">
        All Responses
      </h3>
      {/* Increased bottom margin for better spacing */}

      <div className="flex flex-col md:flex-row md:space-x-6 space-y-6 md:space-y-0">
        {/* Increased horizontal space between child components */}

        <div className="flex-1">
          {/* Allows the component to grow and fill available space */}

          <div className="bg-slate-800 p-4 rounded-lg max-h-[70vh] overflow-y-auto">
            {/* Increased padding for more internal space */}

            <MyResponses responses={responses} username={currentUsername} />
          </div>
        </div>

        <div className="flex-1">
          {/* Allows the component to grow and fill available space */}

          <div className="bg-slate-800 p-4 rounded-lg max-h-[70vh] overflow-y-auto">
            {/* Increased padding for more internal space */}

            <ViewUserResponses
              responses={responses}
              setResponses={setResponses}
              businessName={businessName}
              currentUsername={currentUsername}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Responses;
