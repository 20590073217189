import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Users,
  Trash2,
  Eye,
  EyeOff,
  Shield,
  ShieldOff,
  Link,
} from "lucide-react";

const UserList = ({ businessName, currentUser }) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [inviteLink, setInviteLink] = useState("");
  const [inviteLinkLoading, setInviteLinkLoading] = useState(false);
  const [inviteLinkError, setInviteLinkError] = useState(null);

  useEffect(() => {
    fetchUsers();
  }, [businessName]);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/users`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setUsers(response.data);
      setError(null);
    } catch (err) {
      console.error("Error fetching users:", err);
      setError("Failed to fetch users. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteUser = async (username) => {
    if (window.confirm(`Are you sure you want to delete user ${username}?`)) {
      try {
        const token = localStorage.getItem("access_token");
        await axios.delete(
          `/api/v1/businesses/${businessName}/users/${username}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
        alert(`User ${username} has been deleted successfully.`);
        fetchUsers(); // Refresh the user list
      } catch (err) {
        console.error("Error deleting user:", err);
        alert("Failed to delete user. Please try again.");
      }
    }
  };

  const toggleUserAdmin = async (username, currentStatus) => {
    try {
      const token = localStorage.getItem("access_token");
      await axios.put(
        `/api/v1/businesses/${businessName}/users/${username}/toggle-admin`,
        { is_admin: !currentStatus },
        { headers: { Authorization: `Bearer ${token}` } },
      );
      fetchUsers(); // Refresh the user list
    } catch (err) {
      console.error("Error toggling user admin status:", err);
      alert("Failed to update user admin status. Please try again.");
    }
  };

  const toggleViewAnswers = async (username, currentStatus) => {
    try {
      const token = localStorage.getItem("access_token");
      await axios.put(
        `/api/v1/businesses/${businessName}/users/${username}/toggle-view-answers`,
        { can_view_answers: !currentStatus },
        { headers: { Authorization: `Bearer ${token}` } },
      );
      fetchUsers(); // Refresh the user list
    } catch (err) {
      console.error("Error toggling view answers permission:", err);
      alert("Failed to update view answers permission. Please try again.");
    }
  };

  const generateInviteLink = async () => {
    setInviteLinkLoading(true);
    setInviteLinkError(null);
    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.post(
        `/api/v1/businesses/${businessName}/generate-invite`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setInviteLink(response.data.invite_link);
    } catch (err) {
      console.error("Error generating invite link:", err);
      setInviteLinkError("Failed to generate invite link. Please try again.");
    } finally {
      setInviteLinkLoading(false);
    }
  };

  if (loading) return <div>Loading users...</div>;
  if (error) return <div className="text-red-500">{error}</div>;

  return (
    <div className="mt-8 bg-slate-900 rounded-lg shadow-lg p-6">
      {/* Generate Invite Link Section */}
      <div className="mt-8 bg-slate-800 p-4 rounded-lg mb-8">
        <h4 className="text-lg mb-2 font-serif flex text-indigo-300 items-center">
          <Link className="mr-2 text-teal-400" />
          Generate Invite Link
        </h4>
        <button
          onClick={generateInviteLink}
          disabled={inviteLinkLoading}
          className="w-full py-2 px-4 bg-indigo-900 text-indigo-100 rounded hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
        >
          {inviteLinkLoading ? "generating..." : "generate invite link"}
        </button>
        {inviteLink && (
          <div className="mt-4">
            <p className="text-sm font-bold mb-2 text-white">Invite Link:</p>
            <input
              type="text"
              value={inviteLink}
              readOnly
              className="w-full p-2 bg-slate-700 text-teal-400 rounded"
            />
            <p className="text-xs text-gray-400 mt-2">
              Copy this link and send it to the user you want to invite.
            </p>
          </div>
        )}
        {inviteLinkError && (
          <p className="text-red-500 mt-2">{inviteLinkError}</p>
        )}
      </div>
      <h4 className="text-lg font-semibold mb-4 flex font-serif text-indigo-300 ml-4 items-center">
        <Users className="mr-2 text-indigo-300" />
        User List
      </h4>
      <ul className="space-y-2">
        {users.map((u) => (
          <li
            key={u.id}
            className="flex items-center justify-between py-2 px-4 bg-slate-800 rounded-lg"
          >
            <span>
              {u.username} - {u.email}{" "}
              <span className="text-xs px-2 py-1 bg-slate-500 rounded-full ml-2">
                {u.is_admin ? "Admin" : "User"}
              </span>
              {u.can_view_answers && (
                <span className="text-xs px-2 py-1 bg-green-500 rounded-full ml-2">
                  Can View Answers
                </span>
              )}
            </span>
            <div className="flex items-center">
              {currentUser.is_admin && u.username !== currentUser.username && (
                <>
                  <button
                    onClick={() => toggleUserAdmin(u.username, u.is_admin)}
                    className="p-1 mr-2 text-yellow-400 hover:text-yellow-300 focus:outline-none"
                    title={u.is_admin ? "Remove admin" : "Make admin"}
                  >
                    {u.is_admin ? (
                      <ShieldOff className="h-5 w-5" />
                    ) : (
                      <Shield className="h-5 w-5" />
                    )}
                  </button>
                  <button
                    onClick={() => handleDeleteUser(u.username)}
                    className="p-1 text-red-400 hover:text-red-300 focus:outline-none"
                    title="Delete user"
                  >
                    <Trash2 className="h-5 w-5" />
                  </button>
                </>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default UserList;
